import { useTranslation } from "react-i18next";
import "./locale.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { langSelector, localeActions } from "../../redux/slides/locale.slide";
import i18next from "i18next";
import moment from "moment";
import { useEffect } from "react";
import { authSelector } from "app/redux/slides/auth.slide";
import userServices from "app/services/user.service";
import { profileSelector } from "app/redux/slides/user.slide";

const LocaleButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const auth = useSelector(authSelector);
  const user = useSelector(profileSelector);
  const lang = useSelector(langSelector);

  const langChange = async (key: string) => {
    moment.locale(key);
    await i18next.changeLanguage(key);
    dispatch(localeActions.setLang(key));
    if (auth.isLogined) userServices.changeLanguage(key.toUpperCase());
  };

  useEffect(() => {
    if (auth.isLogined && user) {
      if (!user.locale) {
        userServices.changeLanguage(lang.toUpperCase());
      } else {
        langChange(user.locale.toLowerCase());
      }
    }
  }, [user]);
  return (
    <div className="locale">
      <button
        className="btn"
        onClick={langChange.bind(null, lang === "de" ? "en" : "de")}
      >
        {lang === "de" && (
          <object type="image/svg+xml" data="/images/de.svg"></object>
        )}
        {lang === "en" && (
          <object type="image/svg+xml" data="/images/en.svg"></object>
        )}
      </button>
    </div>
  );
};

export default LocaleButton;
