import services from ".";
import { API } from "../constants/api.constant";
import { SurfaceTreatment } from "../models/SurfaceTreatment";

export const list = async () => {
  try {
    const rs = await services.get(API.MACHINE_SETTINGS);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const update = async (data: SurfaceTreatment) => {
  try {
    const rs = await services.post(API.MACHINE_SETTINGS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const machineSettingServices = {
  list,
  update
};

export default machineSettingServices;
