import { useEffect, useRef, useState } from "react";
import { Alert, Button, Form, Input } from "antd";
import userServices from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadingActions } from "../../redux/slides/loading.slide";
import { useTranslation } from "react-i18next";
import reCAPTCHA from "../../utils/reCAPTCHA";
import ReCAPTCHA from "react-google-recaptcha";
import { configSelector } from "app/redux/slides/config.slide";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

function RegisterForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const config = useSelector(configSelector);
  const [password, setPassword] = useState("");
  const [error, setError] = useState<any>();
  const [captchaToken, setCaptchaToken] = useState<string | undefined>();
  const captchaRef: any = useRef(null);

  const onFinish = async (values: any) => {
    dispatch(loadingActions.show(true));
    try {
      setError(undefined);
      // const token = await reCAPTCHA.getToken(reCAPTCHA.REGISTER_ACTION);
      values.captchaResponse = captchaToken;
      await userServices.register(values);
      navigate("/register/success");
    } catch (e: any) {
      if (
        e.exception ===
        "org.springframework.dao.DataIntegrityViolationException"
      ) {
        e.message = t("error.register.userExisted");
      }
      setError(e);
    }
    dispatch(loadingActions.show(false));
  };
  const onReCaptchaVerify = (token: any) => {
    setCaptchaToken(token);
  };
  const onReCaptchaExpired = () => {
    setCaptchaToken(undefined);
  };

  return (
    <Form name="basic" layout="vertical" onFinish={onFinish} autoComplete="off">
      {!!error && (
        <Alert
          message={error.message}
          type="error"
          closable
          showIcon
          className="mb-3"
        />
      )}
      <div className="row">
        <div className="col col-12">
          <Form.Item
            label={t("email")}
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input autoComplete="new-user" />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("password")}
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("confirmPassword")}
            name="confirmPassword"
            rules={[
              {
                required: !!password,
                validator: async (_, confirmPassword) => {
                  if (password != confirmPassword) {
                    return Promise.reject(new Error("Passwords do NOT match"));
                  }
                  return true;
                },
              },
            ]}
          >
            <Input.Password autoComplete="new-confpassword" />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("firstName")}
            name="firstName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("lastName")}
            name="lastName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item label="Telefon" name="phone" rules={[{ required: true }]}>
            <Input type="tel" />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("companyName")}
            name="companyName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
        </div>
        {config.captchaEnabled && <Form.Item>
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={config.keySiteCaptcha || ""}
            onChange={onReCaptchaVerify}
            onExpired={onReCaptchaExpired}
          />
        </Form.Item>}
        <Form.Item className="mt-1">
          <Button type="primary" htmlType="submit" block disabled={config.captchaEnabled && !captchaToken}>
            {t("register")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

export default RegisterForm;
