import React, { useEffect, useState } from "react";
import { Form, notification, Select, Table } from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { TableFilter } from "../../models/Table";
import surfaceTreatmentServices from "../../services/surfaceTreatment.service";
import { User } from "../../models/User";
import customerServices from "../../services/customer.service";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import CurrencyFormat from "app/components/Format/CurrencyFormat";
import moment from "moment";
const { Option } = Select;

interface TableParams extends SorterResult<User> {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const CustomerPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [data, setData] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [surfaceTreatment, setSurfaceTreatment] = useState<User>();
  const [formMachine] = Form.useForm();

  const getData = async () => {
    setLoading(true);
    try {
      const tableFilters: TableFilter = {
        sortDir: tableParams.order != "descend" ? "ASC" : "DESC",
        sortProperty: tableParams.field?.toString() || "userFullName",
        page: (tableParams.pagination?.current || 1) - 1,
        size: tableParams.pagination?.pageSize,
      };
      const rs = await customerServices.list();
      setData(rs);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: rs.totalItems,
        },
      });
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [JSON.stringify(tableParams)]);

  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<User>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getSurfaceTreatment = async (id: number) => {
    try {
      const rs = await surfaceTreatmentServices.detail(id);
      setSurfaceTreatment(rs);
    } catch (error) {}
  };

  const showModal = (id?: number) => {
    setIsModalOpen(true);
    if (id) {
      getSurfaceTreatment(id);
    }
  };

  const handleCancel = () => {
    setSurfaceTreatment(undefined);
    setIsModalOpen(false);
  };

  const columns: ColumnsType<User> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: true,
    },
    {
      title: t("fullName"),
      dataIndex: "name",
      sorter: true,
    },
    {
      title: t("customer.summary.parts"),
      dataIndex: "numParts",
      sorter: true,
    },
    {
      title: t("customer.summary.order"),
      dataIndex: "orderCostSum",
      sorter: true,
      render: (data: any) => <CurrencyFormat value={data} />,
    },
    {
      title: t("customer.summary.calculations"),
      dataIndex: "numCalculations",
      sorter: true,
    },
    {
      title: t("customer.form.discount"),
      dataIndex: "discount",
      sorter: true,
    },
    {
      title: t("customer.createdAt"),
      dataIndex: "createdAt",
      sorter: true,
      render: (data) => moment(data).format("L"),
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_: any, record: User) => (
        <Link to={`/seller/customers/${record.id}`}>
          <EyeOutlined />
        </Link>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <h3>{t("customer.heading")}</h3>
      <Table
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record) => `customer-${record.id}`}
        dataSource={data}
        // pagination={tableParams.pagination}
        loading={loading}
        // onChange={handleTableChange}
      />
    </>
  );
};

export default CustomerPage;
