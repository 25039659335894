import {
  ArrowLeftOutlined,
  EditOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Collapse,
  Form,
  Radio,
  Space,
  Steps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddressForm from "../../components/Billing/AddressForm";
import DeliveryOptionForm from "../../components/Checkout/DeliveryOptionForm";
import CurrencyFormat from "../../components/Format/CurrencyFormat";
import CadFileItem from "../../components/Part/CadFileItem";
import { DeliveryCost } from "../../models/DeliveryCost";
import { Part } from "../../models/Part";
import { PartViewMode } from "../../models/PartViewMode";
import { Project } from "../../models/Project";
import { authSelector } from "../../redux/slides/auth.slide";
import projectServices from "../../services/project.service";
import "./checkout.module.scss";
import { useTranslation } from "react-i18next";
const { Panel } = Collapse;
const stepItems: any = [
  {
    title: "Delivery option for Automation",
  },
  {
    title: "Billing",
  },
  {
    title: "Review",
  },
];

export function CheckoutPage() {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const [project, setProject] = useState<Project>();
  const [dataAuto, setDataAuto] = useState<Part[]>();
  const [current, setCurrent] = useState(0);
  const [stepData, setStepData] = useState(stepItems);
  const [deliveryCosts, setDeliveryCosts] = useState<DeliveryCost>();

  const getProject = async () => {
    try {
      const rs: Project = await projectServices.detail(id);
      setProject(rs);
      setDataAuto(rs.parts?.filter((p) => p.auto));
      await getDeliveryCosts();
    } catch (error) {
      navigate(`/${auth.user.role}/projects/${id}`);
    }
  };

  const getDeliveryCosts = async () => {
    try {
      const rs: DeliveryCost = await projectServices.getDeliveryCosts(id);
      setDeliveryCosts(rs);
    } catch (error) {}
  };

  useEffect(() => {
    getProject();
  }, []);

  const onNextStep = () => {
    setCurrent(current + 1);
  };

  return (
    <div>
      <Link to={`/${auth.user.role}/projects/${id}`}>
        <ArrowLeftOutlined /> Back to Projects List
      </Link>
      <h1>Checkout</h1>
      <div className="row">
        <div className="col col-12 col-md-12">
          <Steps
            type="navigation"
            size="default"
            current={current}
            // className="site-navigation-steps"
            items={stepItems}
          />
        </div>
      </div>
      {current === 0 && (
        <div className="mt-5">
          <div>
            <h4 className="fw-bold">{t("deliveryOption")}</h4>
            {project && (
              <DeliveryOptionForm
                data={deliveryCosts}
                value={project.deliveryOption}
              />
            )}
          </div>
          <div className="mt-3">
            <h4 className="fw-bold">Parts</h4>
            <div>
              {dataAuto?.map((item) => (
                <CadFileItem
                  key={`part-${item.id}`}
                  part={item}
                  mode={PartViewMode.CHECKOUT}
                />
              ))}
            </div>
          </div>
          <div>
            <Button type="primary" onClick={onNextStep}>
              Next
            </Button>
          </div>
        </div>
      )}
      {current === 1 && (
        <Form layout="vertical">
          <div className="row mt-5">
            <div className="col col-12 col-md-8">
              <div className="row">
                <div className="col-2">
                  <h6 className="fw-bold">Lieferadresse</h6>
                </div>
                <div className="col">
                  <div>
                    Song Quin
                    <br />
                    119 Braintree ST STR 413
                    <br />
                    Boston, MA 111111
                  </div>
                  <AddressForm name="shippingAddress" />
                </div>
                <div className="col-auto">
                  <Button icon={<EditOutlined />}></Button>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col col-2">
                  <h6 className="fw-bold">Rechnungsadresse</h6>
                </div>
                <div className="col">
                  <div>
                    Song Quin
                    <br />
                    119 Braintree ST STR 413
                    <br />
                    Boston, MA 111111
                  </div>
                  <AddressForm name="billingAddress" />
                </div>
                <div className="col-auto">
                  <Button icon={<EditOutlined />}></Button>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-2">
                  <h6 className="fw-bold">Shipping Options</h6>
                </div>
                <div className="col">
                  <Form.Item name="shippingOption" label="">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value="a">Standardversand</Radio>
                        <Radio value="b">Abholung im Werk</Radio>
                        <Radio value="c">
                          Sonderverpackung / via Spedition
                        </Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label="Kommentare zu Verpackung und Lieferung"
                    name=""
                  >
                    <TextArea></TextArea>
                  </Form.Item>
                </div>
              </div>
              <hr />
            </div>
            <div className="col col-12 col-md-4">
              <Card title={t("project.orderSummary")}>
                <Space direction="vertical" size={8} className="w-100">
                  <div className="d-flex">
                    <label>Items (1)</label>
                    <label className="ms-auto">
                      <CurrencyFormat value={320} />
                    </label>
                  </div>
                  <div className="d-flex">
                    <label>Shipping & handling</label>
                    <label className="ms-auto">
                      <CurrencyFormat value={100} />
                    </label>
                  </div>
                  <div className="d-flex fw-bold">
                    <label>Total</label>
                    <label className="ms-auto">
                      <CurrencyFormat value={956} />
                    </label>
                  </div>
                  <div className="mt-3">
                    <Form.Item name="" valuePropName="checked">
                      <Checkbox>
                        I agree with Terms and Conditions and the privacy
                        settings by the seller
                      </Checkbox>
                    </Form.Item>
                    <Form.Item name="">
                      <Button type="primary" block>
                        Place your order
                      </Button>
                    </Form.Item>
                  </div>
                </Space>
              </Card>
              <Alert
                className="mt-3"
                description={
                  <>
                    <ul className="mb-0">
                      <li>
                        After the final approval of the offer by the seller, you
                        will receive a confirmation via e-mail
                      </li>
                      <li>
                        The manual parts will be splitted in to another project
                      </li>
                    </ul>
                  </>
                }
                type="info"
                showIcon
                icon={<InfoCircleOutlined />}
              />
            </div>
          </div>
        </Form>
      )}
    </div>
  );
}
