import {
  CloudUploadOutlined,
  EditOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Card, Form, UploadProps, Button } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { useEffect, useState } from "react";
import MillingSVG from "../../../assets/images/MillingSVG";
import SheetMetalSVG from "../../../assets/images/SheetMetalSVG";
import { CuttingType } from "../../models/CuttingType";
import "./calculation.module.scss";
import { useSelector } from "react-redux";
import { configSelector } from "../../redux/slides/config.slide";
import { ShopType } from "../../models/ShopType";
import { useTranslation } from "react-i18next";
import CustomDxfSheetMetal from "./CustomDxfSheetMetal";

interface DefaultProps {
  onChange?: any;
  onCuttingTypeChange?: any;
  onGenerateDxf?: any;
}

function CalculationStep1(props: DefaultProps) {
  const { onChange, onCuttingTypeChange, onGenerateDxf } = props;
  const { t } = useTranslation();
  const config = useSelector(configSelector);
  const [cuttingType, setCuttingType] = useState(
    config.shopType === ShopType.BOTH ||
      config.shopType === ShopType.MILLING_AND_TURNING
      ? CuttingType.MILLING_AND_TURNING
      : CuttingType.SHEET_METAL
  );
  const [cadFiles, setCadFiles] = useState<any[]>([]);
  const [isCustomDxf, setIsCustomDxf] = useState(false);

  const onCuttingTypeChanged = (v: CuttingType) => {
    setCuttingType(v);
    if (onCuttingTypeChange) {
      onCuttingTypeChange(v);
    }
    if (v != CuttingType.SHEET_METAL) {
      setIsCustomDxf(false);
    }
  };

  useEffect(() => {
    if (onChange && cadFiles.length > 0) {
      const data = {
        cuttingType,
        files: cadFiles,
      };
      onChange(data);
    }
    return () => {};
  }, [cadFiles]);

  const uploadProps: UploadProps = {
    beforeUpload: (file, fileList) => {
      setCadFiles(fileList);
      return false;
    },
    name: "file",
    multiple: true,
    fileList: [],
    accept: `.stp, .step, .jt, .pvz, .edz, .prt, .asm, .sldasm, .sldprt, .model, .catpart, .catproduct, .3ds, .prj, .par, .asm, .psm, .sldprt, .sldasm, .prt, .pdf, ${
      cuttingType === CuttingType.SHEET_METAL ? " .dxf, .dwg," : ""
    } ${cuttingType === CuttingType.MILLING_AND_TURNING ? " .ipt," : ""}`,
  };

  return (
    <>
      <Form>
        {config.shopType === ShopType.BOTH && (
          <>
            <div className="d-flex gap-4 justify-content-center">
              <Card
                className={`cal-option ${
                  cuttingType === CuttingType.MILLING_AND_TURNING && "selected"
                }`}
                onClick={onCuttingTypeChanged.bind(
                  null,
                  CuttingType.MILLING_AND_TURNING
                )}
              >
                {/* <CheckCircleFilled /> */}
                <MillingSVG />
                <p className="sub-l mt-3">
                  {t("cuttingType.MILLING_AND_TURNING")}
                </p>
              </Card>
              <Card
                className={`cal-option ${
                  cuttingType === CuttingType.SHEET_METAL && "selected"
                }`}
                onClick={onCuttingTypeChanged.bind(
                  null,
                  CuttingType.SHEET_METAL
                )}
              >
                {/* <CheckCircleFilled /> */}
                <SheetMetalSVG />
                <p className="sub-l mt-3">{t("cuttingType.SHEET_METAL")}</p>
              </Card>
            </div>
          </>
        )}
        {cuttingType === CuttingType.SHEET_METAL && (
          <div className="mt-3 d-flex gap-4 justify-content-center custom-dxf-btn-group">
            <Button
              onClick={() => setIsCustomDxf(false)}
              className={`mt-2 btn-custom-dxf push-hold-button  ${
                !isCustomDxf ? "selected" : "not-selected"
              }`}
            >
              <CloudUploadOutlined /> Upload file
            </Button>
            <Button
              onClick={() => setIsCustomDxf(true)}
              className={`mt-2 btn-custom-dxf push-hold-button ${
                isCustomDxf ? "selected" : "not-selected"
              }`}
            >
              <EditOutlined /> Custom DXF
            </Button>
          </div>
        )}
        {!isCustomDxf && (
          <div className="mt-5">
            {/* <p className="sub-l">{t("calculation.uploadFiles")}</p> */}
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                {t("calculation.uploadCADFiles")}
              </p>
              <p className="ant-upload-hint px-3">
                {t("calculation.uploadCADFiles.description")}
              </p>
            </Dragger>
          </div>
        )}
      </Form>
      {isCustomDxf && <CustomDxfSheetMetal onGenerate={onGenerateDxf} />}
      {/* <Link className="mt-3" to={`/buyer/calculation/1/parts`}>
        <Button type="primary">Next</Button>
      </Link> */}
    </>
  );
}

export default CalculationStep1;
