import { Form, Input, Select } from "antd";
import { STATES } from "../../constants/state.constant";
import { useTranslation } from "react-i18next";
import Utils from "../../utils";

const { Option } = Select;
interface DefaultProps {
  name?: string;
  title?: any;
  required?: boolean;
}
function AddressForm(props: DefaultProps) {
  const { t } = useTranslation();
  const { title = "", name = "", required = true } = props;
  return (
    <>
      {title && <p className="sub-l">{title}</p>}
      <div className="row">
        <Form.Item name={[name, "id"]} hidden>
          <Input />
        </Form.Item>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("firstName")}
            name={[name, "firstName"]}
            rules={[{ required }]}
          >
            <Input maxLength={30} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("lastName")}
            name={[name, "lastName"]}
            rules={[{ required }]}
          >
            <Input maxLength={30} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.streetName")}
            name={[name, "streetName"]}
            rules={[{ required }]}
          >
            <Input maxLength={60} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.houseNumber")}
            name={[name, "houseNumber"]}
            rules={[{ required }]}
          >
            <Input maxLength={60} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            name={[name, "state"]}
            label={t("address.state")}
            rules={[{ required }]}
          >
            <Select>
              {STATES.map((item) => (
                <Option key={`${title}-${item}`} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.postCode")}
            name={[name, "postCode"]}
            rules={[{ required }]}
          >
            <Input
              type="tel"
              maxLength={7}
              min={0}
              onKeyDown={Utils.triggerTypeNumber}
            />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.city")}
            name={[name, "city"]}
            rules={[{ required }]}
          >
            <Input maxLength={60} />
          </Form.Item>
        </div>
        <div className="col col-12 col-md-6">
          <Form.Item
            label={t("address.country")}
            name={[name, "country"]}
            rules={[{ required }]}
          >
            <Select placeholder="">
              <Option value="DE">Deutschland</Option>
            </Select>
          </Form.Item>
        </div>
      </div>
    </>
  );
}

export default AddressForm;
