import { useEffect, useRef, useState } from 'react'
import './inputInlineApi.module.scss'
import { Button, Col, Form, Input, InputRef, Row, Space } from 'antd'
import { CheckOutlined, CloseOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

interface DefaultProps {
    value?: string
    name: string
    callBack: Function
}

const InputInlineApi = (props: DefaultProps) => {
    const { value, name, callBack } = props
    const [isEdit, setIsEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [tempValue, setTempValue] = useState('')
    const inputRef = useRef<InputRef>(null)
    const [form] = Form.useForm()
    const { t } = useTranslation();
    const errorMsg = t('required')

    // if (!value) {
    //     return <></>
    // }

    useEffect(() => {
        if (value) {
            setTempValue(value)
        }
    }, [value])

    useEffect(() => {
        if (isEdit && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEdit])

    const handleSetEditMode = () => {
        setIsEdit(true)
    }

    const handleInputBlur = async () => {
        const newValue = form.getFieldValue(`${name}`)
        if (tempValue == newValue || newValue.length == 0) {
            setIsEdit(false)
            return
        }
        setIsLoading(true)

        const rs = await callBack(newValue)

        if (rs) {
            setTempValue(rs)
        }

        setIsLoading(false)
        setIsEdit(false)
    }

    const RenderLoading = () => {
        if (isLoading) {
            return <LoadingOutlined />
        }

        return <></>
    }

    const handleCloseEdit = () => {
        form.resetFields()
        
        setIsEdit(false)
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Escape') {
            handleCloseEdit()
        }
    };


    return (
        <div className="input-inline-api">
            {!isEdit && <span className="text-input-value" onClick={handleSetEditMode}>
                {tempValue}
            </span>}
            {!isEdit && !tempValue && <EditOutlined onClick={handleSetEditMode}/>}
            {isEdit &&
                <Form form={form} name={`form-inline-${name}`} style={{position: 'relative'}}>
                    <Form.Item initialValue={tempValue} name={`${name}`} rules={[{ required: true, message: errorMsg }]}>
                        <Input ref={inputRef} onBlur={handleInputBlur} suffix={<RenderLoading />} disabled={isLoading} maxLength={60} onKeyDown={handleKeyDown} />
                    </Form.Item>
                    <Space wrap size={'small'} style={{ position: 'absolute', top: 47, right: 0, zIndex: 9999 }}>
                        <Button icon={<CheckOutlined style={{ fontSize: 15 }} />} size={'small'} style={{ height: 35, width: 35 }} onClick={handleInputBlur} disabled={isLoading} />
                        <Button icon={<CloseOutlined style={{ fontSize: 15 }} />} size={'small'} style={{ height: 35, width: 35 }} onMouseDown={handleCloseEdit} disabled={isLoading} />
                    </Space>
                </Form>
            }
        </div >
    )
}

export default InputInlineApi