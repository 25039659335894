import { useTranslation } from "react-i18next";
import "./locale.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { langSelector, localeActions } from "../../redux/slides/locale.slide";
import i18next from "i18next";
import moment from "moment";
import { Form, FormInstance, Input } from "antd";
import ENIcon from "../SVGs/ENIcon";
import DEIcon from "../SVGs/DEIcon";
import { useEffect } from "react";

interface DefaultProps {
  form: FormInstance<any>;
  data: any;
}
const LocalizationFormItem = (props: DefaultProps) => {
  const { form, data } = props;
  const { t } = useTranslation();
  useEffect(() => {
    if (form && data?.localizations?.length === 0) {
      console.log(data);
      setTimeout(() => {
        form.setFieldValue(["localizations", 0, "locale"], "EN");
        form.setFieldValue(["localizations", 1, "locale"], "DE");
      }, 500);
    }
  }, [data]);
  return (
    <>
      <Form.Item name={["localizations", 0, "id"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={["localizations", 0, "locale"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label={t("localizedNames")}
        name={["localizations", 0, "name"]}
        rules={[{ required: true }]}
        messageVariables={{
          label: "EN Name",
        }}
      >
        <Input prefix={<ENIcon width={"1vw"} height={"1vw"} />} />
      </Form.Item>
      <Form.Item name={["localizations", 1, "id"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={["localizations", 1, "locale"]} hidden>
        <Input />
      </Form.Item>
      <Form.Item
        name={["localizations", 1, "name"]}
        rules={[{ required: true }]}
        messageVariables={{
          label: "DE Name",
        }}
      >
        <Input prefix={<DEIcon width={"1vw"} height={"1vw"} />} />
      </Form.Item>
    </>
  );
};

export default LocalizationFormItem;
