import axios from "axios";
import Utils from "../utils";
export const ROOT_URL = `${process.env.REACT_APP_API_URL}`;

const services = axios.create({
  baseURL: ROOT_URL,
});
services.defaults.timeout = 600000;

services.interceptors.request.use((config) => {
  if (config.params) {
    config.params = Utils.getTrimmedData(config.params);
  }
  if (config.data && !(config.data instanceof FormData)) {
    config.data = Utils.getTrimmedData(config.data);
  }
  return config;
});

export const setAuthorization = (token?: string) => {
  if (!token) {
    services.defaults.headers.common.Authorization = undefined;
    return;
  }
  services.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export default services;
