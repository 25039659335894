/* eslint-disable jsx-a11y/scope */
import { useTranslation } from "react-i18next";
import CurrencyFormat from "../Format/CurrencyFormat";

interface DefaultProps {
  data?: any;
}

function PriceReference(props: DefaultProps) {
  const { data } = props;
  const { t } = useTranslation();
  return (
    <table className="table mb-0 text-white">
      <thead>
        <tr>
          <th scope="col">{t("quantity")}</th>
          <th scope="col">{t("price.unitPrice")}</th>
        </tr>
      </thead>
      <tbody>
        {!!data &&
          Object.keys(data).map((key: any, index) => (
            <tr key={`referenceCosts-${index}`}>
              <td scope="col">{key}</td>
              <td scope="col" className="text-end">
                <CurrencyFormat value={data[key]} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default PriceReference;
