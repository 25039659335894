import { useTranslation } from "react-i18next";
import "./locale.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { langSelector, localeActions } from "../../redux/slides/locale.slide";
import i18next from "i18next";
import moment from "moment";
interface DefaultProps {
  localizations?: any[];
  valueDefault?: string;
}
const LocalizationName = (props: DefaultProps) => {
  const { localizations, valueDefault } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang: string = useSelector(langSelector);
  const localization = localizations?.find(
    (l) => l.locale === lang.toUpperCase()
  );

  return <>{localization?.name || valueDefault}</>;
};

export default LocalizationName;
