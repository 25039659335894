import { createSlice } from "@reduxjs/toolkit";

export const materialSlice = createSlice({
  name: "material",
  initialState: {
    groups: []
  },
  reducers: {
    setGroups: (state, action) => {
      state.groups = action.payload
    }
  },
});


export const materialActions = materialSlice.actions
export const materialSelector = (state: any) => state.material;
export default materialSlice.reducer;
