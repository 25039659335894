import { createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/User";

const initialState: { lang: string | "de" } = { lang: "de" };
export const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLang: (state, action) => {
      state.lang = action.payload;
    },
  },
});

export const localeActions = localeSlice.actions;
export const langSelector = (state: any) => state.locale.lang;
export default localeSlice.reducer;
