import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Form, Input, InputNumber, notification } from "antd";
import { Machine } from "../../models/Machine";
import { machineConstants } from "../../constants/machine.constant";
import { SurfaceTreatment } from "../../models/SurfaceTreatment";
import machineSettingServices from "../../services/machineSetting.service";
interface DefaultProps {
  data: Machine;
  onFinished?: any;
  onSubmit?: any;
}
const MachineForm = (props: DefaultProps) => {
  const { data } = props;
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const renderMachineForm = (item: any) => {
    const keys = Object.keys(item);
    const renderInputs: any[] = [
      <Form.Item key={`${item.id}-id`} name="id" hidden>
        <Input />
      </Form.Item>,
    ];
    keys.map((key: any) => {
      const label =
        typeof machineConstants[key] == "object"
          ? machineConstants[key].label
          : machineConstants[key];
      const description =
        (typeof machineConstants[key] == "object" &&
          machineConstants[key].description) ||
        "";
      const unit =
        (typeof machineConstants[key] == "object" &&
          machineConstants[key].unit) ||
        "";
      switch (key) {
        case "localizedName":
          renderInputs.push(
            <Form.Item
              key={`${item.localizedName}-${key}`}
              label={label}
              name={key}
              // tooltip={!!description && description}
              hidden
            >
              <Input />
            </Form.Item>
          );
          break;
        case "hourlyRate":
        case "maxRotation":
        case "maxLength":
        case "maxWidth":
        case "maxHeight":
        case "maxDiameter":
        case "factorSetupTime":
        case "factorAdditionalTime":
        case "factorProgrammingTimes":
          renderInputs.push(
            <Form.Item
              key={`${item.id}-${key}`}
              label={label}
              name={key}
              tooltip={
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: !!description && description,
                    }}
                  ></div>
                </>
              }
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <InputNumber addonAfter={unit} min={0} />
            </Form.Item>
          );
          break;
        case "cuttingParams":
        case "turningCuttingParams":
        case "millingCuttingParams":
          const childKeys = Object.keys(item[key]);
          childKeys.map((childKey) => {
            renderInputs.push(
              <Form.Item
                key={`${item.id}-${key}-${childKey}`}
                label={machineConstants[`${key}.${childKey}`].label}
                name={[`${key}`, `${childKey}`]}
                tooltip={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          machineConstants[`${key}.${childKey}`].description,
                      }}
                    ></div>
                  </>
                }
              >
                <InputNumber
                  addonAfter={machineConstants[`${key}.${childKey}`].unit}
                  min={0}
                />
              </Form.Item>
            );
          });
          break;
        case "variant1":
        case "variant2":
          renderInputs.push(
            <div key={`${item.id}-${key}`}>
              <Form.Item
                key={`${item.id}-${key}-priority`}
                label={machineConstants[`${key}.priority`].label}
                name={[`${key}`, "priority"]}
                tooltip={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: machineConstants[`${key}.priority`].description,
                      }}
                    ></div>
                  </>
                }
              >
                <InputNumber
                  addonAfter={machineConstants[`${key}.priority`].unit}
                  min={0}
                />
              </Form.Item>
              <Form.Item
                key={`${item.id}-${key}-active`}
                label={machineConstants[`${key}.active`].label}
                name={[`${key}`, "active"]}
                tooltip={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: machineConstants[`${key}.active`].description,
                      }}
                    ></div>
                  </>
                }
                valuePropName="checked"
              >
                <Checkbox></Checkbox>
              </Form.Item>
            </div>
          );
          break;
        default:
          break;
      }
    });
    return <>{renderInputs}</>;
  };

  const onSurfaceTreatmentSubmitted = async (values: SurfaceTreatment) => {
    setSubmitting(true);
    try {
      await machineSettingServices.update(values);
      api.success({
        message: `Update success!`,
        description: `Machine - ${data.localizedName}`,
        placement: "topRight",
      });
    } catch (error: any) {
      api.error({
        message: `Update failed!`,
        description: error?.message,
        placement: "topRight",
      });
    }
    setSubmitting(false);
  };
  return (
    <>
      {contextHolder}
      <Form
        className="mt-3"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 6 }}
        layout="horizontal"
        initialValues={data}
        onFinish={onSurfaceTreatmentSubmitted}
        disabled={submitting}
      >
        {renderMachineForm(data)}
        <Form.Item wrapperCol={{ offset: 6, span: 6 }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {t("saveChanges")}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MachineForm;
