/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Popover,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Upload,
} from "antd";
import type { ColumnsType, TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import materialServices from "../../services/material.service";
import { TableData, TableFilter, TableParams } from "../../models/Table";
import { Material } from "../../models/Material";
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { MaterialGroup } from "../../models/MaterialGroup";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { QuickSetting } from "../../models/QuickSetting";
import quickSettingsServices from "../../services/quickSetting.service";
import { useSelector } from "react-redux";
import { configSelector } from "../../redux/slides/config.slide";
import { ShopType } from "../../models/ShopType";
import { useTranslation } from "react-i18next";
import rawMaterialServices from "app/services/rawMaterial.service";
import { RawMaterial } from "app/models/RawMaterial";
import "./material.module.scss";
import LocalizationFormItem from "app/components/Locale/LocalizationFormItem";
import LocalizationName from "app/components/Locale/LocalizationName";
import NumberFormat from "app/components/Format/NumberFormat";
const { Option } = Select;

const MaterialPage: React.FC = () => {
  const config = useSelector(configSelector);
  const { t } = useTranslation();
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState<Material[]>([]);
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalGroupOpen, setIsModalGroupOpen] = useState(false);
  const [material, setMaterial] = useState<Material>();
  const [materialGroups, setMaterialGroups] = useState<MaterialGroup[]>([]);
  const [materialGroup, setMaterialGroup] = useState<MaterialGroup>();
  const [uploading, setUploading] = useState(false);
  const [formMaterial] = Form.useForm();
  const [formGroup] = Form.useForm();
  const [formQuickSetting] = Form.useForm();
  const [formRawMaterial] = Form.useForm();
  const [formAddRawMaterial] = Form.useForm();
  const [quickSetting, setQuickSetting] = useState<QuickSetting>();
  const [isEditQuickSettings, setIsEditQuickSettings] = useState(false);
  const [text, setText] = useState("");
  const [rawMaterials, setRawMaterials] = useState<RawMaterial[]>([]);
  const getQuickSetting = async () => {
    try {
      const rs = await quickSettingsServices.detail();
      setQuickSetting(rs);
      formQuickSetting.setFieldsValue(rs);
    } catch (error) {}
  };
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record: RawMaterial) =>
    `raw-material-${record.id}` === editingKey;

  const getMaterials = async () => {
    setLoading(true);
    try {
      const tableFilters: TableFilter = {
        sortDir: tableParams.order !== "descend" ? "ASC" : "DESC",
        sortProperty: tableParams.field?.toString(),
        page: (tableParams.pagination?.current || 1) - 1,
        size: tableParams.pagination?.pageSize,
        text,
      };
      const rs: TableData = await materialServices.list(tableFilters);
      setData(rs.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: rs.totalItems,
        },
      });
    } catch (error) {}
    setLoading(false);
  };

  const getMaterialGroups = async () => {
    try {
      const rs = await materialServices.getMaterialGroups({
        size: 50,
        sortDir: "ASC",
        sortProperty: "name",
      });
      setMaterialGroups(rs.data);
    } catch (error) {}
  };

  const getRawMaterials = async (materialId: number) => {
    if (config.shopType === ShopType.SHEET_METAL) return;
    try {
      const rs = await rawMaterialServices.list(materialId);
      setRawMaterials(rs);
    } catch (error) {}
  };

  useEffect(() => {
    getMaterialGroups();
    getQuickSetting();
  }, []);

  useEffect(() => {
    getMaterials();
  }, [JSON.stringify(tableParams), text]);

  useEffect(() => {
    if (material) {
      formMaterial.setFieldsValue(material);
      getRawMaterials(material.id);
    } else {
      formMaterial.resetFields();
    }
  }, [material]);

  useEffect(() => {
    if (materialGroup) {
      formGroup.setFieldsValue(materialGroup);
    } else {
      formGroup.resetFields();
    }
  }, [materialGroup]);

  const handleTableChange: any = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<Material>
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getMaterial = async (id: number) => {
    try {
      const rs = await materialServices.detail(id);
      setMaterial(rs);
    } catch (error) {}
  };

  const getMaterialGroup = async (id: number) => {
    try {
      const rs = await materialServices.getMaterialGroupById(id);
      setMaterialGroup(rs);
    } catch (error) {}
  };

  const showModal = (id?: number) => {
    setIsModalOpen(true);
    if (id) {
      getMaterial(id);
    }
  };

  const showModalGroup = (id?: number) => {
    setIsModalGroupOpen(true);
    if (id) {
      getMaterialGroup(id);
    }
  };

  const onSearch = (e: any) => {
    setText(e.target.value || "");
  };

  const onMaterialSubmitted = async (values: Material) => {
    try {
      if (values.id) {
        await materialServices.update(values);
        let index = data?.findIndex((m) => m.id == values.id);
        data[index] = { ...data[index], ...values };
        setData([...data]);
      } else {
        const rs = await materialServices.create(values);
        const newData = [rs, ...data];
        setData(newData);
      }
      api.success({
        message: `Success!`,
        description: `Material - ${values.name}`,
        placement: "topRight",
      });
      handleCancel();
    } catch (error) {
      api.error({
        message: `Failed!`,
        description: `Material - ${values.name}`,
        placement: "topRight",
      });
    }
  };

  const onGroupSubmitted = async (values: MaterialGroup) => {
    try {
      await materialServices.updateMaterialGroups(values);
      data
        .filter((g) => g.groupId == values.id)
        .map((g) => {
          g.group.cuttingParams = values.cuttingParams;
          g.group.localizations = values.localizations;
        });
      setData([...data]);
      api.success({
        message: `Updated!`,
        description: `Material group ${values.name}`,
        placement: "topRight",
      });
      handleGroupCancel();
    } catch (error) {}
  };

  const handleCancel = () => {
    setMaterial(undefined);
    setIsModalOpen(false);
  };

  const handleGroupCancel = () => {
    setMaterialGroup(undefined);
    setIsModalGroupOpen(false);
  };

  const onFileChanged = async (info: UploadChangeParam<UploadFile<any>>) => {
    // console.log(info.file);
    setUploading(true);
    try {
      await materialServices.importMaterial(info.file);
      await getMaterials();
      api.success({
        message: "Import success!",
        description: "Done!",
        duration: 20,
        placement: "topRight",
      });
    } catch (e: any) {
      api.error({
        message: e.error,
        description: e.message,
        duration: 20,
        placement: "topRight",
      });
    }
    setUploading(false);
  };
  const exportMaterial = async () => {
    try {
      const data = await materialServices.exportMaterial();
      // Creating new object of PDF file
      const blob = new Blob([data], { type: "text/csv" });
      const fileURL = window.URL.createObjectURL(blob);
      // Setting various property values
      let alink = document.createElement("a");
      alink.href = fileURL;
      alink.download = `material-${Date.now()}.csv`;
      alink.click();
    } catch (error) {}
  };

  const columns: ColumnsType<Material> = [
    {
      title: "Id",
      dataIndex: "id",
      width: "50px",
      sorter: true,
    },
    {
      title: t("material.table.group"),
      dataIndex: "group",
      key: "group_name",
      sorter: true,
      render: (data, record, index) => (
        <Button
          type="primary"
          onClick={showModalGroup.bind(null, record.groupId)}
        >
          <LocalizationName
            localizations={data.localizations}
            valueDefault={data.name}
          />
        </Button>
      ),
    },
    {
      title: t("material.table.cuttingParams"),
      dataIndex: "group",
      key: "group_cuttingParams",
      sorter: true,
      render: (data) => <NumberFormat value={data.cuttingParams} />,
    },
    {
      title: t("material.table.name"),
      dataIndex: "name",
      sorter: true,
      render: (data, record, index) => {
        return (
          <LocalizationName
            localizations={record.localizations}
            valueDefault={data}
          />
        );
      },
    },
    {
      title: t("material.table.number"),
      dataIndex: "number",
      sorter: true,
      render: (data) => <NumberFormat value={data} />,
    },
    {
      title: t("material.table.density"),
      dataIndex: "density",
      sorter: true,
      render: (data) => (
        <>
          <NumberFormat value={data} /> g/cm³
        </>
      ),
    },
    {
      title: t("material.table.pricePerKilo"),
      dataIndex: "pricePerKilo",
      sorter: true,
      render: (data) => (
        <>
          <NumberFormat value={data} /> €/kg
        </>
      ),
    },
    {
      title: t("status"),
      dataIndex: "active",
      sorter: true,
      render: (data) =>
        data ? (
          <Tag icon={<CheckOutlined />} color="success">
            {t("active")}
          </Tag>
        ) : (
          <Tag icon={<MinusCircleOutlined />} color="default">
            {t("inactive")}
          </Tag>
        ),
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 100,
      render: (_: any, record: Material) => (
        <Button
          type="text"
          icon={<EditOutlined />}
          onClick={showModal.bind(null, record.id)}
        ></Button>
      ),
    },
  ];

  const editRawMaterial = (record: RawMaterial) => {
    formRawMaterial.setFieldsValue(record);
    setEditingKey(`raw-material-${record.id}`);
  };

  const saveRawMaterial = async (id: number) => {
    try {
      const row = (await formRawMaterial.validateFields()) as RawMaterial;
      const newData = [...rawMaterials];
      const index = newData.findIndex((item) => id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        await rawMaterialServices.update({
          ...item,
          ...row,
        });
        setRawMaterials(newData);
        setEditingKey("");
      }
      api.success({
        message: "Success!",
        description: "Raw material!",
        duration: 5,
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: "Failed!",
        description: "Raw material!",
        duration: 20,
        placement: "topRight",
      });
    }
  };

  const cancelRawMaterial = () => {
    setEditingKey("");
  };

  const createRawMaterial = async (values: any) => {
    console.log(values);
    try {
      values.materialId = material?.id;
      const rs = await rawMaterialServices.create(values);
      setRawMaterials([rs, ...rawMaterials]);
      formAddRawMaterial.resetFields();
    } catch (error) {}
  };

  const rawMaterialColumns: any = [
    {
      title: t("type"),
      dataIndex: "shapeType",
      width: "8vw",
    },
    {
      title: t("diameter"),
      dataIndex: "diameter",
      editable: true,
    },
    {
      title: t("width"),
      dataIndex: "width",
      editable: true,
    },
    {
      title: t("height"),
      dataIndex: "height",
      editable: true,
    },
    {
      title: t("length"),
      dataIndex: "length",
      editable: true,
    },
    {
      title: t("action"),
      key: "operation",
      fixed: "right",
      width: 120,
      render: (_: any, record: RawMaterial) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="text"
              icon={<CheckOutlined />}
              onClick={saveRawMaterial.bind(null, record.id)}
            ></Button>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={cancelRawMaterial}
            ></Button>
          </span>
        ) : (
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={editRawMaterial.bind(null, record)}
          ></Button>
        );
      },
    },
  ];

  const mergedRawMaterialColumns = rawMaterialColumns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: RawMaterial) => ({
        record,
        inputType: "number",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell: React.FC<any> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <InputNumber type="number" />;

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item name={dataIndex} style={{ margin: 0 }}>
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const onQuickSettingSubmit = async (values: any) => {
    if (quickSetting) {
      quickSetting.lwhsShape = values.lwhsShape;
      quickSetting.outerDiameterShape = values.outerDiameterShape;
      try {
        await quickSettingsServices.update(quickSetting);
        setQuickSetting({
          ...quickSetting,
          ...values,
        });
        api.success({
          message: `Update success!`,
          placement: "topRight",
        });
        // formQuickSetting.setFieldsValue(quickSetting);
        setIsEditQuickSettings(false);
      } catch (error) {}
    }
  };

  return (
    <>
      {contextHolder}
      <h3>{t("material.settings")}</h3>
      {config.shopType !== ShopType.SHEET_METAL && (
        <Form
          form={formQuickSetting}
          layout="vertical"
          onFinish={onQuickSettingSubmit}
        >
          <div className="row mb-3">
            <div className="col col-12 col-md-4 col-xl-3 ">
              <Form.Item
                className="mb-0"
                name="outerDiameterShape"
                messageVariables={{
                  label: t("material.setting.outerDiameterShape.label"),
                }}
                label={
                  <Space>
                    <label
                      dangerouslySetInnerHTML={{
                        __html: t("material.setting.outerDiameterShape"),
                      }}
                    ></label>
                    <Popover
                      overlayInnerStyle={{ width: "40vw" }}
                      placement="right"
                      content={
                        <>
                          <img
                            width={"100%"}
                            src="/images/Rund-Zuschlag-Rohmaterial.jpg"
                            alt="ToleranzmaBe_Information_Bild"
                          ></img>
                        </>
                      }
                      trigger="hover"
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </Space>
                }
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={!isEditQuickSettings}
                  addonAfter="mm"
                  min={0}
                />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-4 col-xl-3">
              <Form.Item
                className="mb-0"
                name="lwhsShape"
                messageVariables={{
                  label: t("material.setting.lwhsShape.label"),
                }}
                label={
                  <Space>
                    <label>
                      <label
                        dangerouslySetInnerHTML={{
                          __html: t("material.setting.lwhsShape"),
                        }}
                      ></label>
                    </label>
                    <Popover
                      overlayInnerStyle={{ width: "40vw" }}
                      placement="right"
                      content={
                        <>
                          <img
                            width={"100%"}
                            src="/images/Rechteck-Zuschlag.jpg"
                            alt="ToleranzmaBe_Information_Bild"
                          ></img>
                        </>
                      }
                      trigger="hover"
                    >
                      <InfoCircleOutlined />
                    </Popover>
                  </Space>
                }
                rules={[{ required: true }]}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={!isEditQuickSettings}
                  addonAfter="mm"
                  min={0}
                />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-auto d-flex align-items-end">
              {!isEditQuickSettings && (
                <Button
                  onClick={setIsEditQuickSettings.bind(null, true)}
                  className="small"
                >
                  <EditOutlined />
                </Button>
              )}
              {isEditQuickSettings && (
                <Space>
                  <Button
                    className="small"
                    danger
                    onClick={setIsEditQuickSettings.bind(null, false)}
                  >
                    <CloseOutlined />
                  </Button>
                  <Button type="primary" htmlType="submit" className="small">
                    <CheckOutlined />
                  </Button>
                </Space>
              )}
            </div>
          </div>
        </Form>
      )}
      <div className="d-flex gap-3 mb-3 w-100">
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={showModal.bind(null, 0)}
        >
          {t("material.add")}
        </Button>
        <Upload
          accept="text/csv"
          fileList={[]}
          onChange={onFileChanged}
          beforeUpload={() => {
            return false;
          }}
        >
          <Button icon={<UploadOutlined />} type="primary" loading={uploading}>
            {" "}
            {t("material.importCSV")}
          </Button>
        </Upload>
        <Button
          type="primary"
          icon={<DownloadOutlined />}
          onClick={exportMaterial}
        >
          {t("material.exportCSV")}
        </Button>
        <Input
          style={{ maxWidth: "20vw" }}
          className="ms-auto"
          prefix={<SearchOutlined />}
          placeholder={t("material.search.placeholder") || ""}
          allowClear
          onChange={onSearch}
        />
      </div>
      <Table
        columns={columns}
        showSorterTooltip={false}
        rowKey={(record) => `material-${record.id}`}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}
        onChange={handleTableChange}
      />
      <Modal
        title="Material"
        open={isModalOpen}
        onOk={formMaterial.submit}
        onCancel={handleCancel}
        className="material-modal modal-body-scroll"
      >
        <Form
          className="app-form"
          form={formMaterial}
          layout="vertical"
          name="basic"
          autoComplete="off"
          onFinish={onMaterialSubmitted}
          initialValues={{
            active: true,
          }}
        >
          <div className="row">
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="group" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name="groupId"
              label={t("material.table.group")}
              rules={[{ required: true }]}
            >
              <Select placeholder={t("pleaseSelect")}>
                {materialGroups?.map((item) => (
                  <Option key={`group-${item.id}`} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <div className="col col-12">
              <Form.Item
                label={t("material.table.name")}
                name="name"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col col-12">
              <LocalizationFormItem form={formMaterial} data={material} />
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("material.table.number")}
                name="number"
                rules={[{ required: true }]}
              >
                <InputNumber style={{ width: "100%" }} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("material.table.density")}
                name="density"
                rules={[{ required: true }]}
              >
                <InputNumber addonAfter="g/cm³" width={"100%"} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("material.table.pricePerKilo")}
                name="pricePerKilo"
                rules={[{ required: true }]}
              >
                <InputNumber addonAfter="€/kg" width={"100%"} min={0} />
              </Form.Item>
            </div>
            <div className="col col-12 col-md-6">
              <Form.Item
                label={t("status")}
                name="active"
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={t("active")}
                  unCheckedChildren={t("inactive")}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
        <p className="sub-l">Raw material</p>
        <Form
          form={formAddRawMaterial}
          className="app-form"
          layout="vertical"
          onFinish={createRawMaterial}
        >
          <div className="row align-items-end">
            <div className="col">
              <Form.Item
                className="mb-0"
                name="shapeType"
                label={t("type")}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item className="mb-0" name="diameter" label={t("diameter")}>
                <InputNumber />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item className="mb-0" name="width" label={t("width")}>
                <InputNumber />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item className="mb-0" name="height" label={t("height")}>
                <InputNumber />
              </Form.Item>
            </div>
            <div className="col">
              <Form.Item className="mb-0" name="length" label={t("length")}>
                <InputNumber />
              </Form.Item>
            </div>
            <div className="col col-auto">
              <Button type="primary" htmlType="submit">
                Add New
              </Button>
            </div>
          </div>
        </Form>
        {rawMaterials && (
          <Form form={formRawMaterial} component={false}>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Table
              className="mt-3"
              columns={mergedRawMaterialColumns}
              dataSource={rawMaterials}
              pagination={false}
              rowKey={(record) => `raw-material-${record.id}`}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
            />
          </Form>
        )}
      </Modal>
      <Modal
        title="Material"
        open={isModalGroupOpen}
        onOk={formGroup.submit}
        onCancel={handleGroupCancel}
        destroyOnClose
      >
        <Form
          form={formGroup}
          layout="vertical"
          name="basic"
          autoComplete="off"
          onFinish={onGroupSubmitted}
        >
          <div className="row">
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item label={t("material.table.group")} name="name">
              <Input disabled />
            </Form.Item>
            <LocalizationFormItem form={formGroup} data={materialGroup} />
            <Form.Item name="abbr" hidden>
              <Input />
            </Form.Item>
            <Form.Item name="active" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              label={t("material.table.cuttingParams")}
              name="cuttingParams"
              rules={[{ required: true }]}
            >
              <InputNumber min={0} />
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default MaterialPage;
