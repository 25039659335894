import { Alert, Button, Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { authSelector } from "../../redux/slides/auth.slide";
import { useState, HTMLAttributes, useRef, useEffect } from "react";
import { loadingActions } from "../../redux/slides/loading.slide";
import projectServices from "../../services/project.service";
import { useNavigate } from "react-router-dom";
interface DefaultProps extends HTMLAttributes<any> {
  //   project: Project;
}
function AddProjectButton(props: DefaultProps) {
  //   const { project } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorCreateProject, setErrorCreateProject] = useState<any>(false);
  const [createProjectForm] = Form.useForm();
  const inputElement: any = useRef();

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        inputElement.current.focus();
      }, 300);
    }
  }, [isModalOpen]);

  const showCreateProjectModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    createProjectForm.resetFields();
    setErrorCreateProject(false);
    setIsModalOpen(false);
  };

  const onFinish = ({ name }: any) => {
    createProject(name);
  };

  const createProject = async (name: string) => {
    setErrorCreateProject(false);
    dispatch(loadingActions.show(true));
    try {
      const rs = await projectServices.createByName(name.trim());
      handleCancel();
      navigate(`/${auth.user.role}/projects/${rs.id}/calculations`);
    } catch (error: any) {
      error.message = t("error.project.name.existed");
      setErrorCreateProject(error);
    }
    dispatch(loadingActions.show(false));
  };

  return (
    <>
      <Button
        type="primary"
        onClick={showCreateProjectModal}
        {...props}
        className={`btn-add-project ${props.className}`}
      >
        <PlusOutlined /> {t("addProject")}
      </Button>
      <Modal
        title={t("addProject")}
        open={isModalOpen}
        onOk={createProjectForm.submit}
        onCancel={handleCancel}
        destroyOnClose
      >
        <Form
          form={createProjectForm}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          {errorCreateProject && (
            <Alert
              className="mb-2"
              type="error"
              message={errorCreateProject.message}
            ></Alert>
          )}
          <Form.Item
            label={t("project.table.name")}
            name="name"
            rules={[{ required: true }]}
          >
            <Input maxLength={60} autoFocus ref={inputElement} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
export default AddProjectButton;
