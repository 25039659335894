import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Descriptions,
    Form,
    InputNumber,
    notification,
    Select,
    Space,
    Switch,
} from "antd";
import type { TablePaginationConfig } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { SurfaceTreatment } from "../../models/SurfaceTreatment";
import { useParams } from "react-router-dom";
import { User } from "../../models/User";
import customerServices from "../../services/customer.service";
import ListProjects from "../../components/Project/ListProjects";
import { ProjectStatus } from "../../models/Project";
import { useTranslation } from "react-i18next";
const { Option } = Select;

interface DataType {
  name: {
    first: string;
    last: string;
  };
  gender: string;
  email: string;
  login: {
    uuid: string;
  };
}

interface TableParams extends SorterResult<SurfaceTreatment> {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const CustomerOverviewPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const { t } = useTranslation();
  const { id } = useParams();
  const [customer, setCustomer] = useState<User>();
  const [form] = Form.useForm();
  const [customerSubmiting, setCustomerSubmiting] = useState(false);
  const getCustomer = async () => {
    try {
      const rs = await customerServices.detail(Number(id));
      setCustomer(rs);
      form.setFieldsValue(rs);
    } catch (error) {}
  };
  useEffect(() => {
    getCustomer();
  }, []);

  const updateCustomerSubmit = async (values: any) => {
    setCustomerSubmiting(true);
    try {
      const data = {
        id: customer?.id,
        discount: values.discount,
        calculationEnabled: values.calculationEnabled,
      };
      await customerServices.update(data);
      api.info({
        duration: 0,
        message: `Success!`,
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        duration: 0,
        message: `Failed!`,
        placement: "topRight",
      });
    }
    setCustomerSubmiting(false);
  };

  return (
    <>
      {contextHolder}
      <h3>{t("customer.detail.heading")}</h3>
      <Descriptions title={t("contact")}>
        <Descriptions.Item label={t("fullName")}>
          {customer?.name}
        </Descriptions.Item>
        <Descriptions.Item label={t("email")}>
          {customer?.email}
        </Descriptions.Item>
        <Descriptions.Item label={t("phone")}>
          {customer?.phone}
        </Descriptions.Item>
        <Descriptions.Item label={t("companyName")}>
          {customer?.companyName}
        </Descriptions.Item>
      </Descriptions>
      <hr />
      <div className="row">
        <div className="col col-12 col-md-6">
          <p className="sub-l">{t("billingAddress")}</p>
          <div>
            <p>
              {t("contactPerson")}: {customer?.billingAddress?.firstName}{" "}
              {customer?.billingAddress?.lastName}
            </p>
            <p>
              {customer?.billingAddress?.streetName}{" "}
              {customer?.billingAddress?.houseNumber}
            </p>
            <p>
              {customer?.billingAddress?.postCode}{" "}
              {customer?.billingAddress?.city}
            </p>
            <p>
              {customer?.billingAddress?.state}{" "}
              {customer?.billingAddress?.country}
            </p>
          </div>
        </div>
        <div className="col col-12 col-md-6">
          <p className="sub-l">{t("shippingAddress")}</p>
          <div>
            <p>
              {t("contactPerson")}: {customer?.shippingAddress?.firstName}{" "}
              {customer?.shippingAddress?.lastName}
            </p>
            <p>
              {customer?.shippingAddress?.streetName}{" "}
              {customer?.shippingAddress?.houseNumber}
            </p>
            <p>
              {customer?.shippingAddress?.postCode}{" "}
              {customer?.shippingAddress?.city}
            </p>
            <p>
              {customer?.shippingAddress?.state}{" "}
              {customer?.shippingAddress?.country}
            </p>
          </div>
        </div>
      </div>
      <hr />
      <Form
        form={form}
        labelCol={{ span: 16 }}
        autoComplete="none"
        onFinish={updateCustomerSubmit}
      >
        <div className="row align-items-center">
          <div className="col col-12 col-md-3">
            <Form.Item
              name="discount"
              label={t("customer.form.discount")}
              rules={[{ required: true }]}
            >
              <InputNumber addonAfter="%" min={0}/>
            </Form.Item>
            <Form.Item
              className="mb-0"
              name="calculationEnabled"
              label={t("customer.form.automaticCalculation")}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
          <div className="col col-12 col-md-5">
            <Button type="primary" htmlType="submit">
              {t("save")}
            </Button>
          </div>
        </div>
      </Form>
      <hr />
      <Space size={16} direction="horizontal">
        <Card className="b-shadow">
          <p className="sub-l">{t("customer.summary.parts")}</p>
          <label>{customer?.numParts}</label>
        </Card>
        <Card className="b-shadow">
          <p className="sub-l">{t("customer.summary.order")}</p>
          <label>{customer?.orderCostSum?.toFixed(2)} €</label>
        </Card>
        <Card className="b-shadow">
          <p className="sub-l">{t("customer.summary.calculations")}</p>
          <label>{customer?.numCalculations}</label>
        </Card>
      </Space>
      <hr />
      <h6 className="fw-bold">{t("projects")}</h6>
      <ListProjects
        statuses={[
          ProjectStatus.CHECKOUTED,
          ProjectStatus.REQUESTED_OFFER,
          ProjectStatus.PRICE_ADAPTED,
          ProjectStatus.BUYER_APPROVED,
          ProjectStatus.BUYER_REJECTED,
          ProjectStatus.SELLER_APPROVED,
          ProjectStatus.SELLER_REJECTED,
        ]}
      />
    </>
  );
};

export default CustomerOverviewPage;
