const SheetMetalUnfold = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#0079AD"
      fillOpacity={0.941}
      fillRule="evenodd"
      stroke="#000"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M2.206 11.122H9.84c2.53 0 4.616-2.086 4.616-4.616V1.174h-3.188v5.332c0 .818-.61 1.428-1.428 1.428H2.206v3.188Z"
      clipRule="evenodd"
    />
    <path
      stroke="#000"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M14.194 6.939h-2.701M9.841 10.823v-2.59"
    />
    <path
      fill="#0079AD"
      fillOpacity={0.941}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M19.286 18.539H2.162v3.242h17.124V18.54Z"
    />
    <path
      stroke="#000"
      strokeLinecap="square"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M9.842 21.54v-2.59M12.8 21.426v-2.59"
    />
    <path
      fill="maroon"
      fillRule="evenodd"
      stroke="maroon"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.8}
      d="M16.442 10.275v2.22h-2.504l3.671 3.64 3.67-3.64h-2.502v-2.22h-2.335Z"
      clipRule="evenodd"
    />
  </svg>
)
export default SheetMetalUnfold;
