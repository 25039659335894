import { RawMaterial } from "app/models/RawMaterial";
import services from ".";
import { API } from "../constants/api.constant";

export const list = async (materialId: number) => {
  try {
    const rs = await services.get(
      API.RAW_MATERIALS_BY_MATERIAL.replace(":id", materialId.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const create = async (data: RawMaterial) => {
  try {
    const rs = await services.post(API.RAW_MATERIALS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const update = async (data: RawMaterial) => {
  try {
    const rs = await services.post(API.RAW_MATERIALS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const rawMaterialServices = {
  list,
  create,
  update
};

export default rawMaterialServices;
