import { Form } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ProjectStatus } from "../../models/Project";
import "swiper/css";
import "./project.module.scss";
import { authSelector } from "../../redux/slides/auth.slide";
import ListProjects from "../../components/Project/ListProjects";
import { profileSelector } from "../../redux/slides/user.slide";
import { useTranslation } from "react-i18next";
import { routers } from "../../constants/router.constant";
import AddProjectButton from "../../components/Project/AddProjectButton";

const onChange = (key: string) => {
  // console.log(key);
};

export function SelfProjectsPage() {
  const location = useLocation();
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const [projectListTab, setProjectListTab] = useState("");
  const [title, setTitle] = useState("projects");
  const [description, setDescription] = useState("");

  useEffect(() => {
    const path = location.pathname?.replace(
      `/${auth.user?.role}${routers.SELF_PROJECTS}`,
      ""
    );
    setProjectListTab(path);
    switch (path) {
      case "":
      case "/":
        setTitle("menu.project.seller.self");
        // setDescription("project.draft.description");
        break;
      case "dispatched-offers":
        setTitle("self.tab2");
        setDescription("self.tab2.description");
        break;
      default:
        setTitle("projects");
        break;
    }
  }, [location]);


  return (
    <div>
      <div className="d-flex align-items-center">
        <h4 className="m-0">{t(title)}</h4>
        <div className="ms-auto">
          <AddProjectButton className="m-0" />
        </div>
      </div>
      {(projectListTab === "" || projectListTab === "/") && (
        <ListProjects statuses={[ProjectStatus.IN_PROGRESS]} isSelf />
      )}
    </div>
  );
}
