import { Space } from "antd";
import { useState } from "react";
import { DeliveryOption } from "../../models/DeliveryOption";
import CurrencyFormat from "../Format/CurrencyFormat";
import "./deliveryOptionForm.module.scss";
import { useTranslation } from "react-i18next";

interface DefaultProps {
  data?: any;
  value: DeliveryOption;
  onChange?: any;
}
function DeliveryOptionForm(props: DefaultProps) {
  const { data, value, onChange } = props;
  const { t } = useTranslation();
  const [selected, setSelected] = useState<DeliveryOption>(value);
  const onDeliveryOptionChanged: any = (value: DeliveryOption) => {
    setSelected(value);
    if (onChange) onChange(value);
  };
  return (
    <>
      <div className="text-center mb-5">
        {t("calculation.deliveryOptionNote1")}
        <br />
        {t("calculation.deliveryOptionNote2")}
      </div>
      <div className="row justify-content-center mb-5">
        {data &&
          Object.keys(DeliveryOption).map((key: any, index) => (
            <div key={`deliveryOption-${key}`} className="col col-4 p-4">
              <div
                className={`delivery-option-item b-${key.toLowerCase()} ${
                  selected == key && "selected"
                }`}
                onClick={onDeliveryOptionChanged.bind(null, key)}
              >
                {/* <div className="watermark">
                  <div>goCAD</div>
                  <div>goCAD</div>
                </div> */}
                <h4 className="fw-bold delivery-title">
                  {t(`deliveryOption.${key}`)}
                </h4>
                <h6 className="mt-2">
                  {data[key].packageDeliveryTime} {t("weeks")}
                </h6>
                <hr />
                <Space direction="vertical" className="w-100">
                  <div className="cost-item">
                    <label>{t("price.totalPartPrice")}</label>
                    <label className="price">
                      <CurrencyFormat value={data[key].totalPartPrice} />
                    </label>
                  </div>
                  <div className="cost-item">
                    <label>{t("price.surfacetreatments")}</label>
                    <label className="price">
                      <CurrencyFormat
                        value={data[key].priceSurfaceTreatments?.reduce(
                          (a: number, p: any) => {
                            return a + p.differenceCost;
                          },
                          0
                        )}
                        showZero
                      />
                    </label>
                  </div>
                  <div className="cost-item">
                    <label>{t("price.deliveryOptionPrice")}</label>
                    <label className="price">
                      <CurrencyFormat
                        value={data[key].deliveryOptionPrice}
                        showZero
                      />
                    </label>
                  </div>
                  <div className="cost-item">
                    <label>{t("price.packagingPrice")}</label>
                    <label className="price">
                      <CurrencyFormat value={data[key].packagingPrice} />
                    </label>
                  </div>
                  <div className="cost-item">
                    <label>{t("price.offerPrice")}</label>
                    <label className="price large">
                      <CurrencyFormat value={data[key].offerPrice} />
                    </label>
                  </div>
                </Space>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default DeliveryOptionForm;
